import request from '@/utils/request.js'

// 查询模块列表
export const queryModuleList = (data) => {
  return request({
    method: 'GET',
    url: 'module',
    params: {
      ...data
    }
  })
}

// 查询模块所有信息
export const queryModuleAll = (data) => {
  return request({
    method: 'GET',
    url: 'module',
    params: {
      ...data
    }
  })
}

// 查询级联模块信息
export const queryModuleCascader = (data) => {
  return request({
    method: 'GET',
    url: 'module',
    params: {
      ...data
    }
  })
}

// 添加模块信息
export const addModule = (data) => {
  return request({
    method: 'POST',
    url: 'module',
    data
  })
}

// 编辑模块信息
export const editModule = (data) => {
  return request({
    method: 'PUT',
    url: 'module',
    data
  })
}

// 删除模块信息
export const deleteModule = (data) => {
  return request({
    method: 'DELETE',
    url: 'module',
    data
  })
}
