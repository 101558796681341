<template>
  <div class="module">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        模块管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增模块</el-button>
      </div>
      <!-- 当前模块信息展示 -->
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="模块编号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="module_name"
          label="模块名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="description"
          label="模块描述">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <!-- 测试模块分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisibleModule"
      width="50%"
      @close="closeHandle">
      <el-form :model="ruleFormModule" :rules="ModuleFormRules" ref="ruleFormRefModule" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="模块ID">
          <el-input v-model="ruleFormModule.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="模块名称" prop="module_name">
          <el-input v-model="ruleFormModule.module_name"></el-input>
        </el-form-item>
        <el-form-item label="所属项目" prop="module_name">
          <el-input v-model="ruleFormModule.project_id" :disabled="!isAdd?true:false"></el-input>
        </el-form-item>
        <el-form-item label="模块描述" prop="description">
          <el-input type="textarea" v-model="ruleFormModule.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleModule = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="moduleAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="moduleEdit">编 辑</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryModuleList, addModule, editModule, deleteModule } from '@/services/module.js'
export default {
  name: 'Module',
  data () {
    return {
      isAdd: true,
      tableData: [{
        id: '1',
        module_name: '王小虎',
        description: '上海市普陀区金沙江路 1518 弄'
      }],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      formLabelWidth: '120px',
      dialogVisibleModule: false,
      ruleFormModule: {
        module_name: '',
        description: '',
        project_id: ''
      },
      ModuleFormRules: {
        module_name: [
          { required: true, message: '请输入模块名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入模块描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择模块类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.loadModuleList()
  },
  methods: {
    async loadModuleList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryModuleList(queryObj)
      if (res.status === 1) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    // 模块信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadModuleList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadModuleList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      this.isAdd = true
      this.ruleFormModule = {
        module_name: '',
        description: '',
        project_id: ''
      }
      this.dialogVisibleModule = true
      console.log('新增模块')
    },
    handleEdit (rowData) {
      this.isAdd = false
      this.ruleFormModule = rowData
      this.dialogVisibleModule = true
      console.log('编辑模块')
    },
    // 关闭前操作
    closeHandle () {
      this.ruleFormModule = {
        module_name: '',
        description: '',
        project_id: ''
      }
      this.$refs.ruleFormRefModule.resetFields()
    },
    handleDel (rowData) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { id: rowData.id }
        const { data: res } = await deleteModule(deleteObj)
        if (res.status === 1) {
          this.$message.success('删除用例成功')
          this.loadModuleList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async moduleAdd () {
      const addModuleObj = this.ruleFormModule
      const { data: res } = await addModule(addModuleObj)
      if (res.status === 1) {
        this.dialogVisibleModule = false
        this.$message.success('添加模块成功')
        this.loadModuleList()
      }
    },
    async moduleEdit () {
      const editModuleObj = this.ruleFormModule
      const { data: res } = await editModule(editModuleObj)
      if (res.status === 1) {
        this.dialogVisibleModule = false
        this.$message.success('编辑模块成功')
        this.loadModuleList()
      }
    }
  }
}
</script>

<style>

</style>
